import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/functional-javascript",
  "date": "2015-10-31",
  "title": "FUNCTIONAL JAVASCRIPT",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "To understand Functional JavaScript and the quirks of JavaScript, we will have to look into the history of JavaScript and how it was created."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`When Brendan Eich created JavaScipt, he borrowed features heavily from `}<inlineCode parentName="p">{`Self`}</inlineCode>{` and `}<inlineCode parentName="p">{`Scheme`}</inlineCode>{`.`}</p>
    <h3>{`Self`}</h3>
    <p>{`Self is a powerful somewhat unusual Object Oriented system.`}</p>
    <p>{`JavaScript borrowed following features from Self:`}</p>
    <ul>
      <li parentName="ul">{`Dynamic dispatch`}</li>
      <li parentName="ul">{`Encapsulation`}</li>
      <li parentName="ul">{`Polymorphism — lots of it (weak typing!)`}</li>
      <li parentName="ul">{`Inheritance through the prototype chain`}</li>
      <li parentName="ul">{`Open recursion — through `}<strong parentName="li">{`this`}</strong></li>
    </ul>
    <h3>{`Scheme`}</h3>
    <p>{`But it was Scheme, that JavaScript borrowed most important features of functional languages.`}</p>
    <ul>
      <li parentName="ul">{`First class functions`}</li>
      <li parentName="ul">{`Closures`}</li>
    </ul>
    <h2>{`What's the difference between OOP and FP`}</h2>
    <p>{`There are some significant differences in these two paradigms. OO focuses on the differences in the data, while FP concentrates on consistent data structures.`}</p>
    <p>{`So here we are going to concentrate on the first-class functions.`}</p>
    <h2>{`Composing`}</h2>
    <p>{`Composing multiple functions to create more complex ones is a common utility in any programming language. And the ability to construct functions in a way that is easily composable is a true talent, but it really helps with code maintenance and reuse. It's not uncommon to find huge applications composed of many, much smaller functions.`}</p>
    <Message type="info" title="Functional Composition" content="Functional Composition chains together functions to create a new function." mdxType="Message" />
    <p>{`First things first, let's setup some basic test functions:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var add,
  multiply,
  square,
  sum;

add = function(n) { return n + 1; };
multiply = function(n) { return n * 2; };
square = function(n) { return n * n; };
sum = function(x, y) { return x+ y; };
`}</code></pre>
    <Message type="info" title="Mathematical Composition" content="In mathematics `f ∘ g` (pronounced **f composed with g**) is the function that given **x**, returns **f(g(x))**.
  So if we follow the mathematical model **compose(add1, square)(x)** should equal **add1(square(x))**." mdxType="Message" />
    <p>{`To compose functions together, we will need to accept a list of functions for it to be made up from (eg: `}<strong parentName="p">{`a`}</strong>{`, `}<strong parentName="p">{`b`}</strong>{`, and `}<strong parentName="p">{`c`}</strong>{`). Now need to call each of them with the result of the next function. In JavaScript, we would do this with `}<strong parentName="p">{`a(b(c(x)))`}</strong>{` (`}<strong parentName="p">{`x`}</strong>{` being the starting value). However, it would be much more useful to have something a little more reusable than this.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var compose = function(f, g) {
  return function() {
    return f.call(this, g.apply(this, arguments));
  };
};
`}</code></pre>
    <p>{`The above function iterates over the function list (our arguments) in reverse - the last function to pass in is executed first. Given a single value as the initial input, it'll chain that value between every function call and return the final result. This allows us to do some really cool things.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var run = compose(add, multiply);

run(22); // 45
`}</code></pre>
    <h2>{`Sequence`}</h2>
    <p>{`Sequence is similar to `}<inlineCode parentName="p">{`compose`}</inlineCode>{` but the arguments are executed in reverse.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var sequence = function(f, g) {
  return function() {
    return g.call(this, f.apply(this, arguments));
  };
};
`}</code></pre>
    <p>{`we can reuse the compose function to write the sequence implementation.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var run = sequence(add, multiply);

run(22); // 46
`}</code></pre>
    <h2>{`Reduce`}</h2>
    <p>{`Reduce combines an array into a single value by repeatedly using a function that combines an element of the array with a base value.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var reduce = function(combine, base, array) {
  var i,
    len = array.length;

  for(i = 0; i < len; i++) {
    base = combine(base, array[i]);
  }

  return base;
};
`}</code></pre>
    <p>{`Now we can write a function for sum.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`sum([1, 2, 3]); // 6
`}</code></pre>
    <h2>{`ForEach`}</h2>
    <p>{`ForEach goes over an array, applying a function to every element`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function forEach(array, fn) {
  var i,
    len = array.length;

  for(i = 0; i < len; i++) {
    fn(array[i]);
  }
}
`}</code></pre>
    <p>{`Now we can write an implementation for forEach function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var result = 0;
forEach([1, 2, 3], function(element) {
  result = result + element;
}); // 6
`}</code></pre>
    <Message type="info" title="Array.prototype.forEach()" content="ES5 `forEach()` method executes a provided function once per array element." mdxType="Message" />
    <h2>{`Map`}</h2>
    <p>{`Map goes over an array, applying a function to every element, just like `}<strong parentName="p">{`forEach`}</strong>{`. But instead of discarding the values returned by function, it builds up a new array from these values.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function map(array, fn) {
  var result = [],
    i,
    len = array.length;

  for(i = 0; i < len; i++) {
    result.push(fn(array[i]));
  }

  return result;
}
`}</code></pre>
    <p>{`Now we can write an implementation for map function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`map([1, 2, 3], function(element) {
  return element + 1;
}); // [2, 4, 6]
`}</code></pre>
    <Message type="info" title="Array.prototype.map()" content="EES5 `map()` method creates a new array with the results of calling a provided function on every element in this array." mdxType="Message" />
    <p>{`You can find the source code for this tutorial in `}<a parentName="p" {...{
        "href": "https://github.com/szaranger/functional-javascript"
      }}>{`GitHub`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      